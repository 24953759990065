        <template>
  <div
      >
    <b-row
          >
                                    <b-col
                                                                                                >
                            <news-list />            </b-col>
                            </b-row>
  </div>
</template>
<script>
/* eslint-disable */
// prettier-ignore
import {
  BRow,
  BCol,
} from 'bootstrap-vue'


                                      import NewsList from '@core/components/news-and-documents/news/news-list/NewsList.vue'
                            
export default {
  components: {
    BRow,
    BCol,

                                                          NewsList,
                                          },
  setup() {
    
      },
}
</script>
    